import {createApp} from "vue"
import BootstrapVue3 from "bootstrap-vue-3"
import moment from "moment"

import App from "./App.vue"
import router from "./router"

import "@/assets/scss/config/minimal/app.scss"

moment().locale("fr")

// Define custom replaceOne() method
Object.defineProperty(Array.prototype, 'replaceOne', {
  value: function(where, newValue) {
    const copy = [...this]
    const index = this.findIndex(where)

    if (index >= 0)
      copy[index] = newValue

    return copy
  }
})

createApp(App)
  .use(router)
  .use(BootstrapVue3)
  .mount("#app")
