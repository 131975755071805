import Auth from "@/helpers/Auth"

export default [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    beforeEnter() {
      if (!Auth.isAuthenticated())
        return {name: "login"}
      if (Auth.isFuncAdmin())
        return {name: "dashboard"}
      if (Auth.isTechAdmin())
        return {name: "caisses"}
      if (Auth.isAdmin())
        return {name: "users"}
      if (Auth.isHR() || Auth.isCaisseMember())
        return {name: "po.sirh"}
    },
  },
  {
    path: "/status",
    name: "status",
    component: () => import("../views/public/status.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {onlyGuest: true},
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/errors/500.vue"),
  },
  {
    path: "/:any+",
    name: "not-found",
    component: () => import("../views/errors/404.vue"),
  },
  {
    path: "/",
    meta: {onlyAuth: true},
    children: [
      {
        path: "dashboard",
        children: [
          {
            path: "",
            name: "dashboard",
            component: () => import("../views/dashboard/dashboard.vue"),
            beforeEnter() {
              if (!Auth.isFuncAdmin())
                return {name: "dashboard.caisse"}
            },
          },
          {
            path: "caisse",
            name: "dashboard.caisse",
            component: () => import("../views/dashboard/dashboard-caisse.vue"),
          },
        ],
      },
      {
        path: "po",
        children: [
          {
            path: "sirh",
            name: "po.sirh",
            component: () => import("../views/payment-orders/sirh-po.vue"),
          },
          {
            path: "m3",
            name: "po.m3",
            component: () => import("../views/payment-orders/m3-po.vue"),
          },
        ],
      },
      {
        path: "payments",
        children: [
          {
            path: "all",
            name: "payments.all",
            component: () => import("../views/payments/payments.vue"),
          },
          {
            path: "search",
            name: "payments.search",
            component: () => import("../views/payments/payments.vue"),
          },
        ],
      },
      {
        path: "recipients",
        children: [
          {
            path: "internal",
            name: "recipients.internal",
            component: () => import("../views/recipients/internal-recipients.vue"),
          },
          {
            path: "external",
            name: "recipients.external",
            component: () => import("../views/recipients/external-recipients.vue"),
          },
        ],
      },
      {
        path: "caisses",
        children: [
          {
            path: "",
            name: "caisses",
            component: () => import("../views/caisses/caisses.vue"),
            beforeEnter() {
              if (!Auth.isFuncOrTechAdmin())
                return {name: "caisses.accountant"}
            },
          },
          {
            path: "accountant",
            name: "caisses.accountant",
            component: () => import("../views/caisses/caisses-accountant.vue"),
          },
        ],
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/users/users.vue"),
      },
      {
        path: "settings",
        children: [
          {
            path: "app",
            name: "settings.app",
            component: () => import("../views/settings/app.vue"),
          },
          {
            path: "sites",
            name: "settings.sites",
            component: () => import("../views/settings/sites.vue"),
          },
          {
            path: "directions",
            name: "settings.directions",
            component: () => import("../views/settings/directions.vue"),
          },
          {
            path: "services",
            name: "settings.services",
            component: () => import("../views/settings/services.vue"),
          },
          {
            path: "mapping",
            name: "settings.mapping",
            component: () => import("../views/settings/mapping-matrix.vue"),
          },
          {
            path: "fees",
            name: "settings.fees",
            component: () => import("../views/settings/operator-fees.vue"),
          },
          {
            path: "payment-reasons",
            name: "settings.paymentReasons",
            component: () => import("../views/settings/payment-reasons.vue"),
          },
          {
            path: "api-keys",
            name: "settings.apiKeys",
            component: () => import("../views/settings/operator-api-keys.vue"),
          },
          {
            path: "ad",
            name: "settings.ad",
            component: () => import("../views/settings/active-directory.vue"),
          },
          {
            path: "m3",
            name: "settings.m3",
            component: () => import("../views/settings/erp-m3.vue"),
          },
          {
            path: "sftp",
            name: "settings.sftp",
            component: () => import("../views/settings/sftp-server.vue"),
          },
          {
            path: "smtp",
            name: "settings.smtp",
            component: () => import("../views/settings/smtp-server.vue"),
          },
          {
            path: "proxy",
            name: "settings.proxy",
            component: () => import("../views/settings/proxy-server.vue"),
          },
        ],
      },
    ]
  },
]
