import {createRouter, createWebHistory} from "vue-router"
import routes from "./routes"
import Auth from "@/helpers/Auth"

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {top: 0, left: 0}
  },
})

// Guest middleware
router.beforeEach((to) => {
  if (to.meta.onlyGuest && Auth.isAuthenticated()) {
    return {name: "home"}
  }
})

// Auth middleware
router.beforeEach((to) => {
  if (to.meta.onlyAuth && !Auth.isAuthenticated()) {
    return {name: "login", query: {redirect: to.path}}
  }
})

router.onError((error) => {
  console.error(error)
  window.location = "/error"
})

export default router
