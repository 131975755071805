const meta = require("../../package.json")

export const env = (key, defaultValue = null) => process.env["VUE_APP_" + key] ?? defaultValue

export default {
  MODE: process.env.NODE_ENV,
  APP_NAME: meta.description || "App Name",
  APP_VERSION: meta.version || "1.0.0",
  SERVER_URL: env("SERVER_URL", "http://127.0.0.1"),
  API_PREFIX: env("API_PREFIX", "/api"),
}
