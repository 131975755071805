import Env from "@/helpers/Env"

export default {
  url(path) {
    if (path == null) {
      return path
    }
    if (!path.match(/^\//)) {
      path = "/" + path
    }
    return Env.SERVER_URL + path
  },
  open(path, target = "_blank") {
    window.open(this.url(path), target).focus()
  },
}
