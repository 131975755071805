import moment from "moment"
import Asset from "@/helpers/Asset"

const chunkSplit = (str, chunkLength = 1, separator = " ") => {
  return str.match(new RegExp(".{0," + chunkLength + "}", "g")).join(separator)
}
const strReverse = str => str.split("").reverse().join("")
const ltrim = (str, char = " ") => str.replace(new RegExp(`^${char}+`, "g"), "")
const rtrim = (str, char = " ") => str.replace(new RegExp(`${char}+$`, "g"), "")
const today = () => "Aujourd'hui"
const dateFormat = (dateString, format) => {
  if (dateString) {
    const todayString = moment(undefined, null, "fr").format("L")
    return moment(dateString, null, "fr")
      .format(format)
      .replace(todayString, today())
  }
  return null
}

export const fullName = (person, lastNameFirst = false) => {
  if (person == null)
    return person

  if (!person.firstName)
    return person.lastName

  if (!person.lastName)
    return person.firstName

  return lastNameFirst ? `${person.lastName} ${person.firstName}` : `${person.firstName} ${person.lastName}`
}

export default {
  methods: {
    _default(value = null) {
      return value == null || String(value).match(/^\s*$/) ? "N/A" : value
    },
    fullName,
    today,
    date(dateString) {
      return dateFormat(dateString, "L")
    },
    datetime(dateString, separator = "\n") {
      return dateFormat(dateString, `L[${separator}]HH:mm`)
    },
    number(value) {
      if (value == null)
        return value

      value = parseFloat(value)

      if (value < 0)
        return "-" + this.number(-value)

      if (value !== 0) {
        const parts = (value + "").split(".")
        value = parseInt(parts[0]) &&
          strReverse(chunkSplit(strReverse(ltrim(parts[0], "0")), 3, " ")).trim()

        if (parts.length > 1 && parseInt(parts[1]) !== 0)
          value += "," + rtrim(parts[1], "0")

        value = value.trim()
      }

      return value
    },
    money(amount, currency = "FCFA") {
      if (amount == null)
        return amount

      amount = this.number(amount)

      if (currency)
        return amount + " " + currency

      return amount
    },
    phone(phoneNumber) {
      if (!phoneNumber)
        return phoneNumber

      return this.number(phoneNumber.replace(/^(\+|00)?237/, ""))
    },
    rate(ratio) {
      if (isNaN(ratio))
        return "N/A"

      if (!ratio)
        return ratio

      return Math.round(10000 * ratio) / 100.0 + ' %'
    },
    nl2br(text) {
      if (!text)
        return text

      return text.replace(/\n/g, "<br>")
    },
    flat(html) {
      return html.replace(/<\/?\w*>/ig, "")
    },
    asset(path) {
      return Asset.url(path)
    },
  },
}
